var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.props.isOpen),callback:function ($$v) {_vm.$set(_vm.props, "isOpen", $$v)},expression:"props.isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Assign Virtual Assistants")])],1),_c('div',{},[_c('v-container',[_c('v-card-text',{staticClass:"col-md-6"},[_c('v-card-title',[_vm._v("Filter Virtual Assistants")]),_c('div',{staticClass:"d-flex flex-row flex-wrap"},[_c('v-card-text',{staticClass:"col-6"},[_c('v-text-field',{attrs:{"label":"VA Name","dense":"","hide-details":"","outlined":""},model:{value:(_vm.state.filters.username),callback:function ($$v) {_vm.$set(_vm.state.filters, "username", $$v)},expression:"state.filters.username"}})],1),_c('v-card-text',{staticClass:"col-6"},[_c('v-text-field',{attrs:{"label":"VA Email","dense":"","hide-details":"","outlined":""},model:{value:(_vm.state.filters.email),callback:function ($$v) {_vm.$set(_vm.state.filters, "email", $$v)},expression:"state.filters.email"}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.applyFilters}},[_vm._v("Update Table")]),_c('v-btn',{on:{"click":_vm.clearFilters}},[_vm._v("Reset")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.state.tableData,"single-select":false,"customColumns":_vm.customColumns,"loading":_vm.state.isLoading,"no-data-text":"No Data","calculate-widths":true},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.first_name),expression:"item.first_name"}]},[_c('v-avatar',{attrs:{"size":"30px"}},[_c('img',{attrs:{"src":_vm.resolveProfilePic(item),"alt":"","loading":"lazy"}})]),_c('span',{staticClass:"ml-2 text-capitalize "},[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!item.first_name),expression:"!item.first_name"}]},[_vm._v("No VA")])]}},{key:"item.ssm_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ssm_id))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.email))])]}},{key:"item.manageBtn",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"color":"success","text":"","disabled":_vm.isDisable(item.id, 'assign')},on:{"click":function($event){return _vm.assignVaToClient(item, index)}}},[_vm._v(" ASSIGN")]),_c('v-btn',{attrs:{"color":"error","text":"","disabled":_vm.isDisable(item.id, 'revoke')},on:{"click":function($event){return _vm.removeVaToClient(item)}}},[_vm._v(" REVOKE")])]}}]),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }