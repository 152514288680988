<template>
  <div class="d-flex flex-column px-4 py-4">
    <h2 class="mb-4 cursive-font">Assign VAs</h2>

    <table-with-filters ref="assignmentTable" :options="tableOptions" :customColumns="customColumns">

      <template v-slot:name="{ item }">
        <div>
          <v-avatar size="30px">
            <img :src="resolveProfilePic(item)" alt="" loading="lazy"/>
          </v-avatar>
          <span class="ml-2 text-capitalize ">{{ item.first_name }} {{ item.last_name }}</span>
        </div>
      </template>

      <template v-slot:email="{ item }">
        <div>
          <span class="ml-2">{{ item.email }}</span>
        </div>
      </template>

      <template v-slot:stripe-id="{ item }">
        <span class="text-capitalize">
          {{ item.stripe_id}}
        </span>
      </template>

      <template v-slot:manage-btn="{ item }">
        <v-btn color="info" text @click="openAssignDialog(item.id)"> MANAGE</v-btn>
      </template>

    </table-with-filters>

    <AssignDialog :is-open="isAssignVaDialogOpen" :user="userData" :userID="clientId" @update="updateTable(id)" @close="isAssignVaDialogOpen = false"/>

  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { resolveProfilePic } from "@/helpers";
import { useAssignment } from "@/composables/assignment/assignment.js";
import axios from "@axios";
import TableWithFilters from "@/components/common/TableWithFilters.vue";
import AssignVADialog from "@/components/assignment/AssignVADialog.vue"
import AssignDialog from "@/components/assignment/AssignDialog.vue";

export default {
  name: "ManageAssignment",
  components: { 
    TableWithFilters, 
    AssignDialog 
  },
  setup() {

    const tableHeaders = [
      { text: "Username", value: "username", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Stripe ID", value: "stripe_id", sortable: false },
      { text: "", value: "manageBtn", sortable: false },
    ];

    const customColumns = [
      { slotName: "name", itemValue: "username" },
      { slotName: "email", itemValue: "email" },
      { slotName: "stripe-id", itemValue: "stripe_id" },
      // { slotName: "va-name", itemValue: "vaName" },
      { slotName: "manage-btn", itemValue: "manageBtn" },
    ];

    const filterFields = [
      { label: "Client Name", value: "username", component: "v-text-field" },
      { label: "Client Email", value: "email", component: "v-text-field" },
    ];

    const assignmentTable = ref(null);
    const isAssignVaDialogOpen = ref(false)
    const userData = ref(null)
    const uID = ref(null)
    const clientId = ref("")
    const { setEditTarget, isEditing } = useAssignment();

    function triggerTableUpdate() {
      assignmentTable.value.fetchTableData();
    }

    function showAlert(id){
      alert(`ID : ${id.id} SD`)
    }

    async function updateTable(id){
      try {
        clientId.value = id
         let {data:virtual_assistants} = await axios.get(`/user/${id}/assigned-va`)
         console.log('updateTable : ',virtual_assistants.data)
          userData.value = virtual_assistants.data
          } catch (err) {
            console.log(err)
          }
      
      console.log(clientId.value,"clientId")
    }

    const openAssignDialog = async (id) =>{
      try {
          await updateTable(id)
          clientId.value = id
          isAssignVaDialogOpen.value = !isAssignVaDialogOpen.value
          } catch (err) {
            console.log(err)
          }
    }

    return {
      clientId,
      assignmentTable,
      triggerTableUpdate,
      setEditTarget,
      isEditing,
      showAlert,
      uID,
      resolveProfilePic,
      isAssignVaDialogOpen,
      openAssignDialog,
      tableOptions: {
        endpoint: "/user/clients",
        headers: tableHeaders,
        filtersTitle: "Filter Clients",
        filterFields,
      },
      customColumns,
      AssignVADialog,
      userData,
      
    };
  },
};
</script>

<style>
.v-data-footer__pagination {
  margin-left: auto !important;
}

.v-data-footer__select {
  display: none;
}
</style>
