var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column px-4 py-4"},[_c('h2',{staticClass:"mb-4 cursive-font"},[_vm._v("Assign VAs")]),_c('table-with-filters',{ref:"assignmentTable",attrs:{"options":_vm.tableOptions,"customColumns":_vm.customColumns},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-avatar',{attrs:{"size":"30px"}},[_c('img',{attrs:{"src":_vm.resolveProfilePic(item),"alt":"","loading":"lazy"}})]),_c('span',{staticClass:"ml-2 text-capitalize "},[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])],1)]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.email))])])]}},{key:"stripe-id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.stripe_id)+" ")])]}},{key:"manage-btn",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"info","text":""},on:{"click":function($event){return _vm.openAssignDialog(item.id)}}},[_vm._v(" MANAGE")])]}}])}),_c('AssignDialog',{attrs:{"is-open":_vm.isAssignVaDialogOpen,"user":_vm.userData,"userID":_vm.clientId},on:{"update":function($event){return _vm.updateTable(_vm.id)},"close":function($event){_vm.isAssignVaDialogOpen = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }